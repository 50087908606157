const newsData = [
  {
    title: "渝北6个社区获评全国示范性老年友好型社区",
    subtitle: "重庆市渝北区人民政府 2022年11月0",
    content:
      "日前，国家卫生健康委、全国老龄办发出《关于命名2022年全国示范性老年友好型社区的通知》，在各地推荐的基础上，经过逐级审核和公示，国家卫生健康委、全国老龄办决定命名999个社区为“2022年全国示范性老年友好型社区”，渝北区有6个社区上榜…",
    img: "/newsThumbnail/news1.png",
    url: "http://www.ybq.gov.cn/sy_263/qxdt/202211/t20221102_11253544.html",
  },
  {
    title:
      "陆海之约 相遇重庆丨智享养老 重庆这家公司以数字化赋能养老服务提质增效",
    subtitle: "央广网 2023年11月09日",
    content:
      "老人独居安全如何保障？互联网和大数据如何为养老行业注入新活力？11月8日，跟随“陆海之约”全国网络媒体重庆行——西部陆海新通道主题传播（重庆站）活动的媒体采访团，一起走进立信（重庆）数据科技股份有限公司（以下简称“立信数据”）…",
    img: "/newsThumbnail/news2.png",
    url: "https://cq.cnr.cn/xwsd/20231109/t20231109_526480490.shtml",
  },
  {
    title:
      "我区构建“医+养+科技”三位一体养老服务体系“智慧+”助力老年人老有所依老有所乐老有所为",
    subtitle: "渝北网 2023年02月22日",
    content:
      "“智慧宝”进家庭，养老服务实现数字化；“适老化改造”的推广，让农村高龄老人生活更有“温度”；“嵌入式”医养结合，推动智慧养老深入实施…",
    img: "/newsThumbnail/news3.png",
    url: "http://www.cqybq.gov.cn/ybw_content/2023-02/22/content_10492279.htm",
  },
  {
    title:
      "两个“小玩意”守护老人居家安全 渝北区开展特殊困难老年人居家安全监测服务项目",
    subtitle: "央广网 2023年10月13日",
    content:
      "10月11日，渝北区双龙湖街道龙顺街社区工作人员上门为空巢老人雷志坚的家安装智能门磁和床头呼叫器，网格员张丹仔细叮嘱老人使用方法…",
    img: "/newsThumbnail/news4.png",
    url: "https://mzj.cq.gov.cn/sy_218/bmdt/gzdt/202310/t20231013_12427178.html",
  },
  {
    title: "渝北区仙桃街道启动医养结合项目 为老人搭建智慧化养老服务平台",
    subtitle: "重庆日报 2021年03月19日",
    content:
      "3月19日，渝北区仙桃街道正式启动医养结合项目，为辖区老年人享受高品质生活搭建方便快捷、普惠实用的智慧化养老服务平台。…",
    img: "/newsThumbnail/news5.png",
    url: "https://app.cqrb.cn/economic/2021-03-19/616829_pc.html",
  },
  {
    title: "仙桃街道入选国家智能社会治理实验（养老）基地名单",
    subtitle: "上游新闻 2021年09月15日",
    content:
      "一是整合资源，建立体系。整合辖区养老、医疗、大数据等资源，构建“医 养 科技”“三位一体”智慧化养老服务体系。二是科技赋能，智慧监管。打造“慧年健康养老服务系统”，实时监测老年人居家行为数据…",
    img: "/newsThumbnail/news6.png",
    url: "https://www.cqcb.com/county/yubeiqu/yubeiqunews/2021-09-15/4451555.html",
  },
];

const exampleListData = [
  {
    title: "安全管理",
    icon: "/exampleIcon/shieldIcon.png",
    text: "老人居家安全实时监测，解决突发事件不知晓、响应迟、救助晚等风险。",
  },
  {
    title: "生活服务",
    icon: "/exampleIcon/cloverIcon.png",
    text: "老人或家属可直接线上下单上门服务，满足老人个性化、多样化的需求。",
  },
  {
    title: "医疗服务",
    icon: "/exampleIcon/groupIcon.png",
    text: "线上家医服务，更好地解决老年人看病难的问题，特别是对行动不便的老年人，减少外出就医的不便。",
  },
  {
    title: "健康管理",
    icon: "/exampleIcon/heartsIcon.png",
    text: "线上家医服务，更好地解决老年人看病难的问题，特别是对行动不便的老年人，减少外出就医的不便。",
  },
];

const homeTabsContent = [
  {
    title: "全市首个“三位一体”智慧居家养老服务体系",
    img: "/homeViewTabsImage/img1.png",
    content:
      "街道随时掌握辖区内老人安全、健康情况，并对养老机构上门服务全流程监管，提升街道公共服务能力、市场监管能力、社会治理能力以及数据治理能力。 ",
    name: "优秀案例1",
    isVideo: false,
    tabName: "重庆仙桃街道案例",
  },
  {
    title: "用“数字温度”赋能乡村养老生活",
    img: "/homeViewTabsImage/vid1.mp4",
    content:
      "为村里80岁以上老人家庭提供安全监测服务,建立了“专业社工+互助养老+智慧终端+积分激励”农村互助积分养老运行模式，让村里的高龄老人们享受到乡村振兴的发展成果，提升晚年生活的幸福感、安全感。",
    name: "优秀案例2",
    isVideo: true,
    tabName: "重庆乌牛村案例",
  },
  {
    title: "守护特困老人居家安全，创新探索“物业+养老”模式",
    img: "/homeViewTabsImage/img2.png",
    content:
      "充分利用小区物业的“距离近”、“人员亲”等优势，为渝北区3600户特殊困难老人提供居家安全监测和居家安全监测数据分析服务。当老人在家中发生意外或出现突发情况时，平台将自动触发警报，并立即将信息发送给物业值班员，快速响应并采取相应的救援行动。 ",
    name: "优秀案例3",
    isVideo: false,
    tabName: "重庆渝北区案例",
  },
];

const navSolutionTabData = [
  {
    name: "面向政府",
    col: [
      {
        index: 1,
        content: [
          {
            title: "特殊老人居家安全关爱解决方案",
            url: "",
          },
          {
            title: "智能化安全体验中心解决方案",
            url: "",
          },
          {
            title: "家庭养老床位解决方案",
            url: "",
          },
          {
            title: "上门养老服务监管解决方案",
            url: "",
          },
        ],
      },
      {
        index: 2,
        content: [
          {
            title: "中心站点运营解决方案",
            url: "",
          },
          {
            title: "高龄补贴解决方案",
            url: "",
          },
          {
            title: "机构补贴解决方案",
            url: "",
          },
          {
            title: "助餐监管解决方案",
            url: "",
          },
        ],
      },
      {
        index: 3,
        content: [
          {
            title: "云呼叫中心解决方案",
            url: "",
          },
          {
            title: "养老机构监管解决方案",
            url: "",
          },
        ],
      },
    ],
  },
  {
    name: "面向企业",
    col: [
      {
        index: 1,
        content: [
          {
            title: "居家上门养老服务解决方案",
            url: "",
          },
          {
            title: "居家安全监测服务解决方案",
            url: "",
          },
          {
            title: "养老助餐运营解决方案",
            url: "",
          },
          {
            title: "智慧中心站点运营管理解决方案",
            url: "",
          },
        ],
      },
      {
        index: 2,
        content: [
          {
            title: "智慧养老院解决方案",
            url: "",
          },
          {
            title: "数字化营销解决方案",
            url: "",
          },
          {
            title: "数字化运营解决方案",
            url: "",
          },
          {
            title: "智能化安全体验中心解决方案",
            url: "",
          },
        ],
      },
      {
        index: 3,
        content: [
          {
            title: "适老化改造体验中心解决方案",
            url: "",
          },
        ],
      },
    ],
  },
];

const informationGridData = [
  {
    index: 1,
    items: [
      {
        title: "特殊老人居家安全关爱",
        img: "/informationGridImage/img1.png",
        link: "",
      },
      {
        title: "智能化安全体验中心",
        img: "/informationGridImage/img2.png",
        link: "",
      },
      {
        title: "中心站点运营",
        img: "/informationGridImage/img3.png",
        link: "",
      },
      {
        title: "高龄补贴",
        img: "/informationGridImage/img4.png",
        link: "",
      },
      {
        title: "云呼叫中心",
        img: "/informationGridImage/img5.png",
        link: "",
      },
      {
        title: "养老机构运营",
        img: "/informationGridImage/img6.png",
        link: "",
      },
    ],
  },
  {
    index: 2,
    items: [
      {
        title: "家庭养老床位",
        img: "/informationGridImage/img7.png",
        link: "",
      },
      {
        title: "上门养老服务运营及监管",
        img: "/informationGridImage/img8.png",
        link: "",
      },
      {
        title: "机构补贴",
        img: "/informationGridImage/img9.png",
        link: "",
      },
      {
        title: "智慧养老餐厅",
        img: "/informationGridImage/img10.png",
        link: "",
      },
      {
        title: "智能终端设备集成",
        img: "/informationGridImage/img11.png",
        link: "",
      },
      {
        title: "更多场景",
        img: "/informationGridImage/img12.png",
        link: "",
      },
    ],
  },
];

const solutionsPageBackgroundData = [
  {
    title: "以新理念为引领",
    icon: "/solutionsPageIcon/icon1.png",
    content:
      "习近平总书记强调，各级党委和政府要高度重视并切实做好老龄工作，贯彻落实积极应对人口老为引领龄化国家战略，把积极老龄观、健康老龄化理念融入经济社会发展全过程。",
  },
  {
    title: "数字中国",
    icon: "/solutionsPageIcon/icon2.png",
    content:
      "2023年2月，中共中央、国务院印发了《数字中国建设整体布局规划》。《规划》明确，数字中国建设按照“2522”的整体框架进行布局，即夯实数字基础设施和数据资源体系“两大基础”，推进数字技术与经济、政治、文化、社会、生态文明建设“五位一体”深度融合，强化数字技术创新体系和数字安全屏障“两大能力”，优化数字化发展国内国际“两个环境”。",
  },
  {
    title: "数字重庆",
    icon: "/solutionsPageIcon/icon3.png",
    content:
      "数字重庆建设大会也明确提出加快建设数字重庆“1361”整体构架，形成一批具有重庆辨识度和全国影响力的重大应用。智慧养老应用是实施积极应对人口老龄化国家战略，也是推动数字重庆建设的重要组成部分。",
  },
];

const solutionsPageDescriptionData = [
  {
    title: "市场监管能力",
    content:
      "可提供居家养老监管，社区养老监管，机构养老监管，提升在养老领域的市场监管能力。    ",
    img: "/solutionsPageDescriptionImg/img1.png",
  },
  {
    title: "社会治理能力",
    content:
      "可提供安全风险监测处置；家庭医生医务管理；养老服务机构管理；家庭床位建设运管理；事务通知触达系统；社区老龄事业综合管理，提升政府社会治理能力。    ",
    img: "/solutionsPageDescriptionImg/img2.png",
  },
  {
    title: "公共服务能力",
    content:
      "可定制化建设微服务小程序，搭建云呼叫中心等系统，提升政府为老公共服务能力。    ",
    img: "/solutionsPageDescriptionImg/img3.png",
  },
  {
    title: "数据治理能力",
    content:
      "可搭建基础数据中心；运营数据中心；物联数据中心，提升政府数据治理能力。    ",
    img: "/solutionsPageDescriptionImg/img4.png",
  },
];

const solutionsPageNeedsData = [
  {
    title: "老有所安",
    content: "平台接入物联网硬件设备，实时监测独居老人状态，增强老人安全感。",
    offset: 2,
  },
  {
    title: "老有所养",
    content:
      "平台统一链接服务机构和商家，服务方式多样且便捷，服务质量有保障，多方位提升老人幸福感。",
    offset: 0,
  },
  {
    title: "老有所医",
    content:
      "联合医院、社区服务中心，探索医养结合新模式，全面护航老人健康，提高老人生命健康水平。",
    offset: 0,
  },
  {
    title: "老有所学",
    content:
      "与社区定期组织老年活动，老人可通过线上学习+线下活动相结合，增添老人生活色彩。",
    offset: 0,
  },
  {
    title: "老有所乐",
    content:
      "与社区定期组织老年活动，老人可通过线上学习+线下活动相结合，增添老人生活色彩。",
    offset: 0,
  },
];

const solutionsPageBackgroundData2 = [
  {
    title: "缺乏足够的技术资源和能力",
    icon: "/solutionsPageIcon2/icon1.png",
    content: "养老服务企业无法有效地将数字化技术应用于养老服务中。",
  },
  {
    title: "缺乏服务创新意识",
    icon: "/solutionsPageIcon2/icon2.png",
    content:
      "养老服务企业通常将数字化技术应用于服务中，或者简单地模仿其他企业的服务模式，缺乏自己的特色和创新点。",
  },
  {
    title: "缺乏人性化用户体验",
    icon: "/solutionsPageIcon2/icon3.png",
    content:
      "养老服务企业提供的数字化服务不够人性化，不符合老年人的使用习惯和需求，导致用户体验不佳。",
  },
  {
    title: "缺乏足够的数据整合能力",
    icon: "/solutionsPageIcon2/icon4.png",
    content:
      "养老服务企业需要处理大量的数据，包括老年人的个人信息、健康状况、服务需求等等。然而企业将数据转化为数字化服务的需求和机会效率较低。",
  },
];

const smeSolutionsInfoData = [
  {
    title: "缺乏足够的技术资源和能力",
    icon: "/smeSolutionInfoIcon/icon1.png",
    content: "养老服务企业无法有效地将数字化技术应用于养老服务中。",
  },
  {
    title: "缺乏服务创新意识",
    icon: "/smeSolutionInfoIcon/icon2.png",
    content:
      "养老服务企业通常将数字化技术应用于服务中，或者简单地模仿其他企业的服务模式，缺乏自己的特色和创新点。",
  },
  {
    title: "缺乏人性化用户体验",
    icon: "/smeSolutionInfoIcon/icon3.png",
    content:
      "养老服务企业提供的数字化服务不够人性化，不符合老年人的使用习惯和需求，导致用户体验不佳。",
  },
  {
    title: "缺乏足够的数据整合能力",
    icon: "/smeSolutionInfoIcon/icon4.png",
    content:
      "养老服务企业需要处理大量的数据，包括老年人的个人信息、健康状况服务需求等等。然而企业将数据转化为数字化服务的需求和机会效率较低。",
  },
];

const smeSolutionDescriptionData = [
  {
    title: "新一代信息技术研发实力强劲",
    buttonText: "专业技能",
    content:
      "已为100余个社区提供智慧养老服务，并已建立从数据采集到管理到治理到分析到应用的全体系数据生态链，确保数据在各个环节的高效流转，为客户提供高效信息产品以及可靠的数据支持和应用价值。",
  },
  {
    title: "数据服务实战经验丰富",
    buttonText: "实战经验",
    content:
      "立信数据已经积累长达22年的数据服务经验和专业知识，深入参与各类中大型项目，熟悉各行业特点，能够为客户定制量身打造高质量、创新性的信息化产品和研究方案，助力他们取得更加卓越的成果。",
  },
  {
    title: "项目执行组织管理优势突出",
    buttonText: "组织管理",
    content:
      "拥有丰富的信息化建设、社会研究、民意调查项目的组织、管理经验，应对大型评估及调查工作管理经验丰富，制度完善。",
  },
];

const smeSolutionTableData = [
  {
    title: "办公协作数字化管理",
    content: [
      "针对养老企业内部协作",
      "任务管理",
      "日程安排",
      "文档共享",
      "实时协作",
      "...",
    ],
    offset: 2,
  },
  {
    title: "运营数字化管理",
    content: [
      "针对养老企业运营",
      "老年客户入退住管理",
      "服务计划制定",
      "护理记录",
      "养老评估",
      "供应商管理",
      "用餐管理",
      "...",
    ],
    offset: 0,
  },
  {
    title: "站点数字化管理",
    content: [
      "针对站点内容",
      "用户体验优化",
      "数据分析",
      "安全管理",
      "营销数字化管理",
      "...",
    ],
    offset: 0,
  },
  {
    title: "数据智能化管理",
    content: [
      "智能化数据收集，分析和可视化",
      "支撑养老企业战略决策",
      "为运营优化提供精准数据",
      "...",
    ],
    offset: 0,
  },
  {
    title: "营销数字化管理",
    content: [
      "对养老企业营销活动进行全面管理/优化",
      "品牌推广",
      "数字广告",
      "社交媒体营销等方面",
      "...",
    ],
    offset: 0,
  },
];

export {
  newsData,
  exampleListData,
  homeTabsContent,
  navSolutionTabData,
  informationGridData,
  solutionsPageBackgroundData,
  solutionsPageDescriptionData,
  solutionsPageNeedsData,
  solutionsPageBackgroundData2,
  smeSolutionsInfoData,
  smeSolutionDescriptionData,
  smeSolutionTableData,
};
