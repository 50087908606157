<template>
  <div class="widget-wrapper">
    <div class="inner-wrapper">
      <router-link to="/register" target="_blank">
        <div class="gift-wrapper">
          <img src="../assets/giftIcon.png" alt="giftIcon" />
          <p>免费试用</p>
        </div>
      </router-link>
    </div>

    <div class="inner-wrapper inner-wrapper2">
      <el-popover placement="left" width="100%" trigger="hover">
        <div class="popover-content">
          <img src="../assets/weixinQR.png" alt="weixinQR" />
        </div>
        <div slot="reference" class="trigger-wrapper">
          <img src="../assets/weChatIcon.png" alt="weChatIcon" />
          <p>添加微信</p>
        </div>
      </el-popover>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
a {
  text-decoration: none;
}

p {
  padding: 0;
  margin: 15px;
  color: var(--black-200);
  transition: color 0.2s;
  font-size: 0.6rem;
}
.widget-wrapper {
  position: fixed;
  bottom: calc(max(30px, 2vw));
  right: calc(max(30px, 2vw));
  width: 5vw;
  min-width: 90px;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gift-wrapper {
  width: 5vw;
  min-width: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inner-wrapper:hover {
  background: #06dd6a;
  transition: all 0.2s;
}

.inner-wrapper:hover img {
  filter: brightness(0) invert(1);
}
.gift-wrapper:hover > p {
  color: var(--white-100);
  transition: color 0s;
}

.gift-wrapper > img {
  width: 35%;
  height: fit-content;
  margin-top: 20px;
}

.trigger-wrapper {
  width: 5vw;
  min-width: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
}

.trigger-wrapper:hover {
  cursor: pointer;
}

.trigger-wrapper:hover > p {
  color: var(--white-100);
  transition: color 0s;
}

.trigger-wrapper > img {
  width: 40%;
  height: fit-content;
  margin-top: 20px;
}

.inner-wrapper {
  width: 5vw;
  min-width: 90px;
  height: 5vw;
  min-height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-top: 10px;
  transition: all 0.2s;
}

.trigger-wrapper > p,
.gift-wrapper > p {
  font-size: clamp(1rem, 0.9vw, 0.9vw);
  margin: 8px;
}

.trigger-wrapper > p {
  margin-bottom: 15px;
}

@media (-webkit-min-device-pixel-ratio: 1.25) {
  .widget-wrapper {
    min-width: 50px;
    max-width: 95px;
    bottom: calc(min(30px, 2vw));
    right: calc(min(30px, 2vw));
  }
  .inner-wrapper {
    min-width: 50px;
    max-width: 95px;
    min-height: 50px;
    border-radius: 0.5vw;
  }

  .trigger-wrapper {
    min-width: 50px;
    max-width: 95px;
  }

  .gift-wrapper {
    min-width: 50px;
    max-width: 95px;
  }

  .trigger-wrapper > p,
  .gift-wrapper > p {
    font-size: clamp(0.5rem, 0.9vw, 0.9vw);
    margin: 1px;
  }

  .trigger-wrapper > img,
  .gift-wrapper > img {
    margin-top: 4px;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.25) {
  .widget-wrapper {
    /* top: 30.5vh; */
    /* width: 70px; */
  }
  /* 
  .inner-wrapper {
    height: 70px;
    width: 70px;
  }

  .trigger-wrapper {
    width: 70px;
  }

  .trigger-wrapper > p,
  .gift-wrapper > p {
    font-size: 0.8rem;
    margin: 4px;
  }

  .trigger-wrapper > img {
    width: 35%;
    height: fit-content;
    margin: 5px;
  }
  .gift-wrapper > img {
    width: 30%;
    height: fit-content;
    margin: 10px;
    margin-bottom: 0;
  }

  .gift-wrapper > img {
    margin-top: 10px;
  } */
}

@media (-webkit-min-device-pixel-ratio: 1.5) {
  .widget-wrapper {
    /* top: 38vh; */
    /* width: 60px; */
  }

  /* .inner-wrapper {
    height: 60px;
    width: 60px;
  }

  .trigger-wrapper {
    width: 60px;
  }

  .trigger-wrapper > p,
  .gift-wrapper > p {
    font-size: 0.7rem;
    margin: 5px;
  }

  .trigger-wrapper > img {
    width: 35%;
    height: fit-content;
    margin: 5px;
    margin-top: 10px;
  }
  .gift-wrapper > img {
    width: 30%;
    height: fit-content;
    margin: 5px;
    margin-top: 10px;
  } */
}
</style>
