var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('footer',{staticClass:"footer"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"footer-col"},[_c('h4'),_c('ul',[_vm._m(0),_c('li',[_c('router-link',{attrs:{"to":"/solution1"}},[_c('a',{attrs:{"href":"#"}},[_vm._v("养老服务企业解决方案")])])],1),_c('li',[_c('router-link',{attrs:{"to":"/solution2"}},[_c('a',{attrs:{"href":"#"}},[_vm._v("政府养老解决方案")])])],1)])]),_c('div',{staticClass:"footer-col"},[_c('h4'),_c('ul',[_vm._m(1),_c('li',[_c('router-link',{attrs:{"to":{
                name: 'examples',
                query: { tab: '重庆仙桃街道案例' },
              }}},[_c('a',{attrs:{"href":"#"}},[_vm._v("智慧居家养老服务体系")])])],1),_c('li',[_c('router-link',{attrs:{"to":{
                name: 'examples',
                query: { tab: '重庆乌牛村案例' },
              }}},[_c('a',{attrs:{"href":"#"}},[_vm._v("“数字乡村”")])])],1),_c('li',[_c('router-link',{attrs:{"to":{
                name: 'examples',
                query: { tab: '重庆渝北区案例' },
              }}},[_c('a',{attrs:{"href":"#"}},[_vm._v("“物业+养老”模式")])])],1)])]),_c('div',{staticClass:"footer-col"},[_c('h4'),_c('ul',[_vm._m(2),_c('li',[_c('router-link',{attrs:{"to":"/news"}},[_c('a',{attrs:{"href":"#"}},[_vm._v("新闻动态")])])],1)])]),_vm._m(3)]),_c('div',{staticClass:"copyright-wrapper"},[_vm._m(4),_vm._v(" |  "),_c('img',{attrs:{"src":_setup.StateEmblem}}),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('b',[_vm._v("解决方案")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('b',[_vm._v("优秀案例")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('b',[_vm._v("新闻动态")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"footer-col"},[_c('h4'),_c('ul',[_c('li',[_c('b',[_vm._v("关于我们")])]),_c('li',[_c('div',[_vm._v("联系电话："),_c('span',[_vm._v("17725001559 19122514080")])])]),_c('li',{attrs:{"id":"address"}},[_c('div',[_vm._v("地址：")]),_c('div',{staticClass:"align-left"},[_vm._v("重庆市渝北区仙桃数据谷东路19号A11-2F")])]),_c('li',[_c('div',{staticClass:"weixin-wrapper"},[_c('div',[_vm._v("客服微信：")]),_c('img',{attrs:{"src":require("../assets/weixinQR.png")}})])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a',{attrs:{"href":"https://beian.miit.gov.cn","target":"_blank"}},[_c('span',{staticClass:"ICP-text"},[_vm._v("渝ICP备2022010029号")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a',{attrs:{"href":"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50011202504254","target":"_blank"}},[_c('span',{staticClass:"ICP-text"},[_vm._v("渝公网安备50011202504254号")])])
}]

export { render, staticRenderFns }